<script>
import ServiceBase from "@/services/ServiceBase";
const restServiceBase = new ServiceBase();

import ApiMethods from "@/classes/common/enums/ApiMethods.js";

export default class UserService {
  async GetAccountInfo(errorCallback) {
    let result = await restServiceBase.get(
      ApiMethods.ACCOUNT_INFO_GET_ACCOUNT_INFO,
      errorCallback
    );

    return result;
  }

  async GetAccountInfoMasterData(errorCallback) {
    const result = await restServiceBase.get(
      ApiMethods.ACCOUNT_INFO_GET_ACCOUNT_INFO_MASTER_DATA,
      errorCallback
    );

    return result;
  }

  async SaveAccount(data, errorCallback) {
    let result = await restServiceBase.post(
      ApiMethods.ACCOUNT_INFO_SAVE_ACCOUNT,
      data,
      errorCallback
    );

    return result;
  }

  async ChangePassword(data, errorCallback) {
    let result = await restServiceBase.post(
      ApiMethods.ACCOUNT_INFO_CHANGE_PASSWORD,
      data,
      errorCallback
    );

    return result;
  }

  async Login(userName, password, errorCallback) {
    let result = await restServiceBase.post(
      ApiMethods.AUTH_LOGIN,
      {
        userName: userName,
        password: password,
      },
      errorCallback
    );
    return result;
  }

  async RefreshToken(token, errorCallback) {
    let result = await restServiceBase.post(
      ApiMethods.AUTH_REFRESH_TOKEN,
      {
        token: token,
      },
      errorCallback
    );
    return result;
  }

  async RevokeToken(token, errorCallback) {
    let result = await restServiceBase.post(
      ApiMethods.AUTH_REVOKE_TOKEN,
      {
        token: token,
      },
      errorCallback
    );
    return result;
  }
}
</script>
