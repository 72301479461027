import MessageTypes from "@/classes/common/enums/MessageTypes";
import { useToast } from "vue-toastification";

function ToCurrency(value) {
      var decPlaces = 4,
        decSeparator = ".",
        thouSeparator = ",",
        sign = value < 0 ? "-" : "",
        i = parseInt(value = Math.abs(+value || 0).toFixed(decPlaces)) + "",
        j = (j = i.length) > 3 ? j % 3 : 0;
        var result = sign + (j ? i.substr(0, j) + thouSeparator : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thouSeparator) + (decPlaces ? decSeparator + Math.abs(value - i).toFixed(decPlaces).slice(2) : "");
  return ("$" + result).replace("$-","-$");
}

function showMessage(type, message, callback) {
  const { error, info, success } = useToast();
  type = type || MessageTypes.Info;

  if (type === MessageTypes.Info) {
    info(message, {
      onClose: callback,
    });
  }

  if (type === MessageTypes.Error) {
    error(message, {
      onClose: callback,
    });
  }

  if (type === MessageTypes.Success) {
    success(message, {
      onClose: callback,
    });
  }

  if (type === MessageTypes.Warning) {
    info(message, {
      onClose: callback,
    });
  }
}

function PreventCharacterInput(e){
  let allowedKeys = [8, 46, 38, 40, 37, 39];
  var keypressed = e.which || e.keyCode;
  if (/[^0-9\s]+/.test(e.key)) {
      if(allowedKeys.every( e => e !== keypressed)) {
          e.preventDefault();
      }
  }
}

function ShowErrorMessage(message, callback) {
  showMessage(MessageTypes.Error, message, callback);
}

function ShowSuccessMessage(message, callback) {
  showMessage(MessageTypes.Success, message, callback);
}

function ShowWarningMessage(message, callback) {
  showMessage(MessageTypes.Warning, message, callback);
}

function ShowInfoMessage(message, callback) {
  showMessage(MessageTypes.Info, message, callback);
}

export default {
  ToCurrency: ToCurrency,
  ShowErrorMessage: ShowErrorMessage,
  ShowSuccessMessage: ShowSuccessMessage,
  ShowWarningMessage: ShowWarningMessage,
  ShowInfoMessage: ShowInfoMessage,
  PreventCharacterInput: PreventCharacterInput
};
