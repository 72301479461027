<template>
  <div  class="footer pad-15">
    &#169; {{info.year}} - {{info.owner}}    |   Version: {{info.version}}
  </div>
</template>

<script>
export default {
  name: "SiteFooter",
  props:{
    owner :{
      type: String,
      default: 'R1 System'
    },
    version:{
      type: String,
      default: '1.0.0.0'
    },
    year:{
      type: Number,
      default: function(){
        return new Date().getFullYear();
      },
      validator(value){
        return value <= new Date().getFullYear();
      }
    }
  },
  data(){
    const info = {};
    let self = this;

    info.owner = self.owner || 'R1 System';
    info.version = self.version || '1.0.0.0';
    info.year = self.year || (function(){
      return new Date().getFullYear();
    }());

    return {
      info
    }
  }
}
</script>

<style scoped>

</style>