<script>
import ServiceBase from '@/services/ServiceBase'
const restServiceBase = new ServiceBase();

import ApiMethods from '@/classes/common/enums/ApiMethods.js'

export default class AccountService {
    async GetAccountInfo(errorCallback){
        return await restServiceBase.get(ApiMethods.ACCOUNT_INFO_GET_ACCOUNT_INFO, errorCallback);
    }
    async GetARSummary(errorCallback){
        return await restServiceBase.get(ApiMethods.ACCOUNT_STATUS_GET_AR_SUMMARY, errorCallback);
    }
    async GetPrintInvoiceHtml(rmaNMumber,errorCallback){
        return await restServiceBase.get(`${ApiMethods.ACCOUNT_STATUS_GET_GET_PRINT_INVOICE_HTML}/${rmaNMumber}`, errorCallback);
    }
    async GetTransactionHistory(data, errorCallback){
        return await restServiceBase.post(ApiMethods.ACCOUNT_STATUS_GET_TRANSACTION_HISTORY, data, errorCallback);
    }
    async GetChecksReceived(data, errorCallback){
        return await restServiceBase.post(ApiMethods.ACCOUNT_STATUS_GET_CHECKS_RECEIVED, data, errorCallback);
    }
    async GetCheck(data, errorCallback){
        return await restServiceBase.post(ApiMethods.ACCOUNT_STATUS_GET_CHECK, data, errorCallback);
    }
}
</script>