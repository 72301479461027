const ApiMethods = Object.freeze({
  // Account Status
  ACCOUNT_STATUS_GET_CHECKS_RECEIVED: "/AccountStatus/GetChecksReceived",
  ACCOUNT_STATUS_GET_TRANSACTION_HISTORY:
    "/AccountStatus/GetTransactionHistory",
  ACCOUNT_STATUS_GET_CHECK: "/AccountStatus/Check",
  ACCOUNT_STATUS_GET_RMA_DETAILS: "/AccountStatus/GetRMADetails",
  ACCOUNT_STATUS_GET_AR_SUMMARY: "/AccountStatus/GetARSummary",
  ACCOUNT_STATUS_GET_GET_PRINT_INVOICE_HTML: "/AccountStatus/GetPrintInvoiceHtml",

  // AR History
  AR_HISTORY_GET_AR_HISTORY: "/ArHistory/GetArHistory",

  // Account Info
  ACCOUNT_INFO_GET_ACCOUNT_INFO: "/AccountInfo/GetAccountInfo",
  ACCOUNT_INFO_GET_ACCOUNT_INFO_MASTER_DATA:
    "/AccountInfo/GetAccountInfoMasterData",
  ACCOUNT_INFO_SAVE_ACCOUNT: "/AccountInfo/SaveAccount",
  ACCOUNT_INFO_CHANGE_PASSWORD: "/AccountInfo/ChangePassword",

  // Auth
  AUTH_LOGIN: "/Auth/Login",
  AUTH_REFRESH_TOKEN: "/Auth/RefreshToken",
  AUTH_REVOKE_TOKEN: "/Auth/RevokeToken",

  // Order 
  ORDER_SEARCH_PART: "/Order/SearchPart",
  ORDER_GET_PART_INFO: "/Order/GetPartInfo",
  ORDER_SEARCH_MULTI_PART: "/Order/SearchMultiPart",
  ORDER_SAVE_QTY_ORDER: "/Order/SaveQtyOrder",
  ORDER_DELETE_CART_ITEM: "/Order/DeleteCartItem",
  ORDER_GET_INTER_CHARGES: "/Order/GetInterchanges",
  ORDER_GET_SHOPPING_CART: "/Order/GetShoppingCart",
  ORDER_DELETE_SHOPPING_CART: "/Order/DeleteShoppingCart",
  ORDER_SELECT_CART: "/Order/SelectCart",
  ORDER_NEW_CART: "/Order/NewCart",
  ORDER_SAVE_CART: "/Order/SaveCart",
  ORDER_GET_ADDRESS:"/Order/GetAddress",
  ORDER_GET_COUNTRIES:"/Order/GetCountries",
  ORDER_GET_STATE_BY_COUNTRY:"/Order/GetStatesByCountry",
  ORDER_GET_SHIPPING_METHODS:"/Order/GetShippingMethods",
  ORDER_SAVE_IMPORT_EXCEL:"/Order/SaveImportExcel",
  ORDER_SUBMIT_ORDER:"/Order/SubmitOrder",
  ORDER_SEARCH_IMAGE:"/Order/SearchImage",
  ORDER_GET_PERMISSION:"/Order/GetPermission",
  ORDER_CHECK_DUPLICATE_PO:"/Order/CheckDuplicatePO",
  ORDER_VALIDATE_BEFORE_SUBMIT_ORDER:"/Order/ValidateBeforeSubmitOrder",  
  ORDER_GET_RATE_SHIPPING_COST: "/Order/GetRateShippingCost",
  ORDER_GET_ORDER_STATUS: "/OrderStatus/GetOrderStatus",
  ORDER_CALCULATE_TAX_AMOUNT: "/Order/CalculateTaxAmount",
  
  // Order status
  ORDER_STATUS_GET_ORDER_STATUS: "/OrderStatus/GetOrderStatus",
  ORDER_STATUS_GET_ORDER_DETAILS: "/OrderStatus/GetOrderDetails",
  ORDER_STATUS_GET_GET_PRINT_INVOICE_HTML: "/OrderStatus/GetPrintInvoiceHtml",
});

export default ApiMethods;
