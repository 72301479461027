import { createRouter, createWebHistory } from 'vue-router'
const AccountStatus = () => import(/* webpackChunkName: "AccountStatus" */ '@/views/Account/AccountStatus.vue')
const AccountInfo = () =>  import(/* webpackChunkName: "AccountInfo" */ '@/views/Account/AccountInfo.vue')
const AccountLogin = () =>  import(/* webpackChunkName: "AccountLogin" */ '@/views/Account/AccountLogin.vue')
const ARHistory = () =>  import(/* webpackChunkName: "ARHistory" */ '@/views/AccountingReport/ARHistory.vue')
const OrderAndStockCheck = () =>  import(/* webpackChunkName: "OrderAndStockCheck" */ '@/views/Order/OrderAndStockCheck.vue')
const CheckOrderStatus = () =>  import(/* webpackChunkName: "CheckOrderStatus" */ '@/views/Order/CheckOrderStatus.vue')

const routes = [
  // Account
  {
    path: '/account-status',
    name: 'Account Status',
    component: AccountStatus
  },
  {
    path: '/account-info',
    name: 'Account Info',
    component: AccountInfo
  },
  {
    path: '/login',
    name: 'Account Login',
    component: AccountLogin
  },
  // Order
  {
    path: '/ordering-and-stock-check',
    name: 'Ordering And Stock Check',
    component: OrderAndStockCheck
  },
  {
    path: '/check-order-status',
    name: 'Check Order Status',
    component: CheckOrderStatus
  },
  //Accounting Report
  {
    path: '/accounting-report-history',
    name: 'AR History',
    component: ARHistory
  },
  {
    path: '/',
    name: 'Ordering/Stock Check',
    component: OrderAndStockCheck
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name == undefined) {
    document.title = 'Ordering And Stock Check';
  }
  else{
    document.title = to.name;
  }
  next();
});
export default router
