<template>
    <nav id="nav">
        <ul v-if="WebAccess!=null">
            <li v-if="WebAccess.AccessAccountingData">
                <router-link 
                    :class="GetDefaultActiveClass('/account-status',$router.currentRoute.value.path)" 
                    active-class="nav-active" 
                    to="/account-status">
                    Account Status
                </router-link>
            </li>
            <li>
                <router-link 
                    :class="GetDefaultActiveClass('/check-order-status',$router.currentRoute.value.path)" 
                    active-class="nav-active" 
                    to="/check-order-status">
                    Check Order Status
                </router-link>
            </li>
            <li v-if="WebAccess.AccessAccountingData">
                <router-link 
                    :class="GetDefaultActiveClass('/accounting-report-history',$router.currentRoute.value.path)" 
                    active-class="nav-active" 
                    to="/accounting-report-history">
                    AR History
                </router-link>
            </li>
            <li>
                <router-link 
                    :class="GetDefaultActiveClass('/ordering-and-stock-check',$router.currentRoute.value.path)" 
                    active-class="nav-active" 
                    to="/ordering-and-stock-check">Ordering/Stock check</router-link>
            </li>
            <li>
                <router-link 
                    :class="GetDefaultActiveClass('/account-info',$router.currentRoute.value.path)" 
                    active-class="nav-active" 
                    to="/account-info">My Information</router-link>
            </li>
        </ul>
    </nav>
    
</template>

<script>
export default {
    name: 'MainNav',
    data(){
        return {
            WebAccess: null,
        }
    },
    mounted: function(){
        var _this = this;
        if(localStorage.WebAccess != undefined){
            _this.WebAccess = JSON.parse(localStorage.WebAccess);
        }
    },
    methods:{
        GetDefaultActiveClass(menuPath,currentPath){
            var defaultPath = process.env.VUE_APP_DEFAULT_LOGIN_PAGE;

            if(defaultPath == menuPath && currentPath == '/'){
                return 'nav-active'
            }
            else{
                return '';
            }
        }
    }
}
</script>