import { createApp } from "vue";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";


import App from './App.vue';
import router from './router';
import VueSweetalert2 from 'vue-sweetalert2';
import Loading from 'vue3-loading-screen';
import Utils from '@/classes/helpers/utils.js';
import vfmPlugin from 'vue-final-modal';
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

const app = createApp(App);

// add font
library.add(fas, far, fab);
dom.watch();
app.component("font-awesome-icon", FontAwesomeIcon);

// use plugins
app.use(router);
app.use(Utils);
app.use(vfmPlugin({
    key: '$vfm',
    componentName: 'VueFinalModal',
    dynamicContainerName: 'ModalsContainer'
  }));
app.use(Loading, {
  bg: "#FFFFFFAD",
  icon: "refresh",
  slot: `<div class="semipolar-spinner" :style="spinnerStyle">
            <div class="ring"></div>
            <div class="ring"></div>
            <div class="ring"></div>
            <div class="ring"></div>
            <div class="ring"></div>
        </div>`,
  size: 15,
  icon_color: "white",
});
app.use(VueSweetalert2);
app.use(Toast, {
  position: POSITION.TOP_RIGHT,
  timeout: 5000,
});
app.mount("#app");
