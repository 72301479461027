<template>
  <div id="app">
    <MasterPage 
      v-if="isLoggedIn" 
      ref="MasterPage"/>
    <div>
      <router-view />
    </div>
    <SiteFooter/>
    <VueUniversalModal />
  </div>

  <vue-final-modal 
    v-model="isShowLoginModal"
    :esc-to-close="false"
    :prevent-click="false"
    :click-to-close="false">
    <div class="modal-wrapper" style="width:500px">
      <div class="modal-title text-center">
          <h4>Account Login</h4>
      </div>
      <div class="modal-body">
        <LoginForm @LoginSuccess="LoginSuccess" :IsPopup="true"/>
      </div>
    </div>
  </vue-final-modal>
</template>

<script>
  import MasterPage from '@/views/MasterPage.vue';
  import UserService from '@/services/user/UserService';
  import VueJwtDecode from 'vue-jwt-decode';
  import LoginForm from '@/components/account/LoginForm';
  import SiteFooter from "@/components/common/SiteFooter";

  const userService = new UserService();

  export default {
    data(){
      return {
        isLoggedIn: false,
        refreshInterval: null,
        checkExpiredTimeout: null,
        userToken: null,
        isLoading: false,
        isShowLoginModal: false
      }
    },
    components:{
      SiteFooter,
      MasterPage,
      LoginForm
    },
    mounted:  function(){
      var _this = this;
      if(localStorage.userToken != undefined){
        _this.isLoggedIn = true;
        
        if(window.location.href.includes('/login')){
          window.location.href = process.env.VUE_APP_DEFAULT_LOGIN_PAGE;
        }
        else{
          var isExprired = _this.CheckAccountExpired();
          if(!isExprired){
            _this.InitApp();
          }
        }
      }
      else{
        _this.$router.push('login');
      }
    },
    methods:{
      InitApp(){
        var _this = this;
        _this.SetTimeRefreshToken();

        if(localStorage.WebAccess == undefined){
          var userToken = JSON.parse(localStorage.userToken);
          localStorage.WebAccess = VueJwtDecode.decode(userToken.accessToken).WebAccess; 
        }

        if(_this.checkExpiredTimeout!=null){
          window.clearTimeout(_this.checkExpiredTimeout);
        }
        _this.checkExpiredTimeout = setInterval(() => {
          if(!_this.isShowLoginModal){
            _this.CheckAccountExpired();
          }
        }, 5000);
      },
      SetTimeRefreshToken: function(){
        var _this = this;
        _this.userToken = JSON.parse(localStorage.userToken);
        var expireTime = new Date(_this.userToken.expiresIn);
        var now = new Date();
        var timeDiff = expireTime - now;
        
        if(_this.refreshInterval != null){
          window.clearInterval(_this.refreshInterval);
        }
        _this.refreshInterval = setTimeout(async () => {
          var refreshToken = await userService.RefreshToken(
            _this.userToken.refreshToken,
            function(){
              _this.Logout();
            }
          );
          if(refreshToken.data.isSuccess){
            _this.userToken.accessToken = refreshToken.data.value.accessToken;
            _this.userToken.refreshToken = refreshToken.data.value.refreshToken;
            _this.userToken.expiresIn = refreshToken.data.value.expiresIn;
            localStorage.userToken = JSON.stringify(_this.userToken);
            _this.SetTimeRefreshToken();
          }
          else{
            _this.Logout();
          }
        }, timeDiff);
      },
      Logout(){
        var _this = this;
        
        var userToken = JSON.parse(localStorage.userToken);
        userService.RevokeToken(userToken.accessToken);
        localStorage.clear();
        _this.$router.push('login');
      },
      CheckAccountExpired(){
        var _this = this;
        var result = false;
        var currentTime = new Date();
        var lastApiCall = new Date(localStorage.lastApiCall);
        var timeDiff = currentTime - lastApiCall;

        if(timeDiff >= process.env.VUE_APP_FORCE_USER_LOGIN){
          var userToken = JSON.parse(localStorage.userToken);
          userService.RevokeToken(userToken.accessToken);
          localStorage.clear();
          _this.isShowLoginModal = true;
          result = true;
        }
        return result;
      },
      async LoginSuccess(){
        var _this = this;
        if(localStorage.WebAccess == undefined){
          var userToken = JSON.parse(localStorage.userToken);
          localStorage.WebAccess = VueJwtDecode.decode(userToken.accessToken).WebAccess; 
        }
        if(localStorage.accountInfo != undefined) {
          _this.accountInfo = JSON.parse(localStorage.accountInfo);
        }
        _this.$refs.MasterPage.$refs.SiteHeader.InitiateData();
        _this.isShowLoginModal = false;
        _this.InitApp();
      }
    }
  }
</script>
<style lang="css">
  @import './assets/bootstrap/css/bootstrap.min.css';
  @import './assets/css/sweetalert2.min.css';
</style>

<style lang="scss">
  @import './assets/css/master.scss';
  @import './assets/css/common-rules.scss';
</style>

<style lang="css">
.vfm--prevent-none[data-v-2836fdb5]{
  pointer-events:all !important
}
</style>