<script>
import axios from 'axios';
axios.defaults.withCredentials = true;
var baseUrl = process.env.VUE_APP_API_BASE_URL;
var apiVersion = process.env.VUE_APP_API_VERSION;
var appLoginPath = process.env.VUE_APP_LOGIN_PATH;

export default class ServiceBase {
    async post(path, data, errorCallback) {
        var reqUrl = location.protocol + "//" + baseUrl + "/api/" + apiVersion + path;
        var result = await axios.post(reqUrl, data, this.GetHeader())
        .catch(function(err){
            if(err.response.status==401){
                localStorage.clear();
                window.location.href = appLoginPath;

            }
            else{
                if(errorCallback != undefined && typeof(errorCallback) == 'function'){
                    errorCallback(err);
                }
            }
        });
        
        return result;
    }
    async put(path, data, errorCallback) {
        var reqUrl =  location.protocol + "//" + baseUrl + "/api/" + apiVersion + path;
        var result = await axios.put(reqUrl, data, this.GetHeader())
        .catch(function(err){
            if(err.response.status==401){
                localStorage.clear();
                window.location.href = appLoginPath;
            }
            else{
                if(errorCallback != undefined && typeof(errorCallback) == 'function'){
                    errorCallback(err);
                }
            }
        });
        
        return result;
    }
    async get(path, errorCallback) {
        var reqUrl =  location.protocol + "//" + baseUrl + "/api/" + apiVersion + path;
        var result =  await axios.get(reqUrl, this.GetHeader())
        .catch(function(err){
            if(err.response.status==401){
                localStorage.clear();
                window.location.href = appLoginPath;
            }
            else{
                if(errorCallback != undefined && typeof(errorCallback) == 'function'){
                    errorCallback(err);
                }
            }
        });
        return result;
    }
    GetHeader(){
        var config = {};
        if(localStorage.userToken != undefined) {
            var userToken = JSON.parse(localStorage.userToken);
            config = {
                headers: {
                    'authorization':'bearer ' + userToken.accessToken,
                }
            }
        }
        localStorage.lastApiCall = new Date();
        return config;
    }
}

</script>