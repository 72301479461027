<template>
    <div id="FormBody" :class="'card-body ' + (IsPopup ? '':'p-4 p-sm-5')">
        <h3 v-if="!IsPopup" class="card-title text-center mb-3 fw-light">
            <strong>
                Log In
            </strong>
        </h3>
        <div>
            <div class="form-floating mb-3">
                <input v-on:keyup.enter="Login()" v-model="UserName" type="text" :class="'form-control ' + ((BtnLoginClicked && UserName=='')?'is-invalid':'')" id="floatingInput" placeholder="User Name">
                <label for="floatingInput">User Name</label>
                <span class="invalid-feedback">User Name is required.</span>
            </div>
            <div class="form-floating mb-3">
                <input v-on:keyup.enter="Login()" v-model="Password" type="password" :class="'form-control ' + ((BtnLoginClicked && Password=='')?'is-invalid':'')" id="floatingPassword" placeholder="Password">
                <label for="floatingPassword">Password</label>
                <span class="invalid-feedback">Password is required.</span>
            </div>
            <div class="d-grid">
                <input v-on:click="Login()" class="btn btn-danger btn-login text-uppercase fw-bold" value="Sign in" type="button"/>
            </div>
        </div>
    </div>
</template>

<script>
    import UserService from '@/services/user/UserService'
    import AccountInfo from '@/services/user/AccountService.vue';

    const accountInfo = new AccountInfo();
    const userService = new UserService();
    
    export default {
        props:["IsPopup"],
        data() {
            var _this = this;
            return {
                UserName:'',
                Password:'',
                IsLoggedIn: _this.$parent.$parent.isLoggedIn,
                BtnLoginClicked: false
            }
        },
        mounted:function() {
            var _this = this;
            if(_this.IsLoggedIn) {
                window.location.href = process.env.VUE_APP_DEFAULT_LOGIN_PAGE;
            }
        },
        methods:{
            async Login(){
                var _this = this;

                if(_this.UserName!='' && _this.Password != '')
                {
                    _this.$isLoading(true);
                    var result = await userService.Login(_this.UserName, _this.Password,
                        function(err){
                            _this.$swal.fire({
                                    icon: 'warning',
                                    title: err.message,
                                    showConfirmButton: false,
                                    timer: 150000
                                });
                            _this.$isLoading(false);
                        });
                    _this.$isLoading(false);
                    if(result.data.isSuccess) {
                        localStorage.userToken = JSON.stringify(result.data.value);
                        localStorage.loggedInUserName = _this.UserName;

                        var accInfo = await accountInfo.GetAccountInfo();
                        if(accInfo.data.isSuccess){
                            localStorage.accountInfo = JSON.stringify(accInfo.data.value);
                        }

                        if(!_this.IsPopup){
                            window.location.href = process.env.VUE_APP_DEFAULT_LOGIN_PAGE;
                        }
                        else{
                            window.location.reload();
                        }
                        _this.$emit('LoginSuccess');
                    }
                    else{
                        if(result.data.errors.length > 0){
                            this.$swal.fire({
                                icon: 'warning',
                                title: result.data.errors[0],
                                showConfirmButton: false,
                                timer: 150000
                            })
                        }
                    }
                }
                
                _this.BtnLoginClicked = true;
            }
        } 
    }
</script>

<style lang="scss" scoped>
.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
}

.btn-google {
  color: white !important;
  background-color: #ea4335;
}

.btn-facebook {
  color: white !important;
  background-color: #3b5998;
}

</style>