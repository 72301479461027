<template>
    <div class="header-wrapper">
        <SiteHeader ref="SiteHeader"/>
    </div>
    <div>
        <MainNav/>
    </div>
    
</template>
<script>
import MainNav from "@/components/common/MainNav.vue";
import SiteHeader from "@/components/common/SiteHeader.vue";

export default {
  data() {
    return {};
  },
  components: {
    MainNav,
    SiteHeader,
  },
  mounted: function () {},
};
</script>
