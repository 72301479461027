<template>
    <div id="SiteHeader">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <img alt="R1 logo" style="cursor: pointer;" @click="GotoHomePage" src="@/assets/images/images.png"/>
                </div>
                <div class="col-md-6">
                    <div class="fr pad-t-5">
                        <span class="mar-r-5">
                            <a @click="GotoCart" style="cursor: pointer;" class="a-red mar-r-5" title="View cart">
                                <font-awesome-icon icon="shopping-cart" />
                            </a>
                            <span class="splitter">|</span>
                            Logged in as {{loggedInUserName || ''}}
                        </span>
                        <span class="mar-l-25">
                            <a v-on:click="Logout()" href="#" class="a-red" title="Log off">Log out</a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import AccountInfo from '@/services/user/AccountService.vue'
    const accountInfo = new AccountInfo();
    export default {
        data(){
            return {
                accountInfo: {
                    fullName:''
                },
                loggedInUserName:''
            }
        },
        mounted: async function(){
            var _this = this;
            await _this.InitiateData();
        },
        methods:{
            Logout:function(){
                var _this = this;
                _this.$parent.$parent.Logout();
                window.location.href=process.env.VUE_APP_LOGIN_PATH;
            },
            GotoHomePage(){
                var _this = this;
                _this.$router.push('/');
            },
            GotoCart(){
                this.$router.push('/ordering-and-stock-check?openCart=true');
            },
            async InitiateData(){
                var _this = this;
                _this.loggedInUserName = localStorage.loggedInUserName;
                if(localStorage.accountInfo == undefined) {
                    var result = await accountInfo.GetAccountInfo();
                    if(result.data.isSuccess){
                        _this.accountInfo = result.data.value;
                        localStorage.accountInfo = JSON.stringify(_this.accountInfo);
                    }
                }
                else{
                    _this.accountInfo = JSON.parse(localStorage.accountInfo);
                }
            }
        }
    }
</script>